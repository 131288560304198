import React from 'react';
import postHelpers from '../helpers/post-helpers';

export default class UploadPicture extends React.Component {
	constructor(props) {
		super(props);
		this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
		this.handleFileSelect = this.handleFileSelect.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			description: '',
			selectedFile: null,
			imageData: []
		};
		this.reader = new FileReader();
	}

	handleDescriptionChange(e) {
		this.setState({ description: e.target.value });
	}

	handleFileSelect(e) {
		var file = e.target.files[0];
		this.reader.readAsDataURL(file)
		this.reader.onload = function(e) {
			this.setState({
				selectedFile : file,
				imageData: e.target.result
			});		
		}.bind(this);
	}

	handleSubmit(e) {
		e.preventDefault();
		postHelpers.uploadPicture(this.state.description, this.state.selectedFile, this.props.onUploadComplete);
	}

	render() {
		return (
			<React.Fragment>
				<div class="field">
					<label htmlFor="Description">Description</label>
					<input id="Description" 
						value={this.state.description} 
						onChange={this.handleDescriptionChange}
						name="Description" type="text" />
				</div>
				<div class="field">
					<input type="file" id="File" name="File" onChange={this.handleFileSelect} />
				</div>
				<img src={this.state.imageData} />
				<input type="submit" value="Submit" onClick={this.handleSubmit} />
			</React.Fragment>
		);
	}
}