var rootUrl = (window.globals && window.globals.rootUrl) || "../";
var csrfToken = (window.globals && window.globals.csrfToken) || null;

function getPictures(callback) {
	fetch(rootUrl + 'API/GetPictures', { method: 'GET' })
	.then(function(response){
		return response.json();
	}).then(function(result) {
		callback(result);
	});
}

function deletePicture(id, title) {
	var reallyDelete = confirm("Really delete the following image?\n" + title);
	if (!reallyDelete) {
		return false;
	}
	fetch(rootUrl + 'API/DeletePicture', {
		method: 'POST',
		headers: {
			"RequestVerificationToken": csrfToken,
			"Accept": "application/json, text/plain, */*",
			"Content-Type" : "application/json"
		},
		credentials: 'same-origin',
		body: JSON.stringify({
			Id: id
		})
	}).then(function(resultSuccess){
		if (resultSuccess.ok) {
			alert('Delete succeeded');
		} else {
			alert('Delete failed');
		}
	});
}

function sanitizePostBody(postBody, callback) {
	fetch(rootUrl + 'API/SanitizePostBody', {
		method: 'POST',
		headers: {
			"RequestVerificationToken": csrfToken,
			"Accept": "application/json, text/plain, */*",
			"Content-Type" : "application/json"
		},
		credentials: 'same-origin',
		body: JSON.stringify({
			PostBody: postBody
		})
	}).then(function(response){
		return response.text();
	}).then(function(result){
		callback(result);
	});
}

function slugify(textToSlugify, callback) {
	fetch(rootUrl + 'API/Slugify/' + textToSlugify, {
		method: 'GET'
	}).then(function(response){
		return response.text();
	}).then(function(slugifiedTitle){
		callback(slugifiedTitle);
	});
}

function uploadPicture(description, file, callback) {
	var formData = new FormData();
	formData.append('Description', description);
	formData.append('File', file);
	fetch(rootUrl + 'API/UploadPicture', {
		method: 'POST',
		headers: {
			"RequestVerificationToken": csrfToken
		},
		credentials: 'same-origin',
		body: formData
	}).then(function(response){
		return response.text();
	}).then(function(result){
		callback(result);
	});
}

export default {
	getPictures: getPictures,
	deletePicture: deletePicture,
	uploadPicture: uploadPicture,
	sanitizePostBody: sanitizePostBody,
	slugify: slugify
};