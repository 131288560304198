import React from 'react';

class Tab extends React.Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(e) {
		if (this.props.onTabClicked) this.props.onTabClicked(name);
	}

	render() {
		return (
			<div className="tab" onClick={this.handleClick}>
				{this.props.children}
			</div>
		);
	}
}

class Tabs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedIndex: 0
		};
		this.handleTabClick = this.handleTabClick.bind(this);
		this.renderTabRibbonItem = this.renderTabRibbonItem.bind(this);
	}

	handleTabClick(index, propsOnClick) {
		if(propsOnClick) propsOnClick();
		this.setState({ selectedIndex: index });
	}

	renderTabRibbonItem(tab, index) {
		var tabRibbonItemClasses = "tab-ribbon-item" + ((this.state.selectedIndex == index)? " active" : "");
		return (
			<div className={tabRibbonItemClasses} onClick={this.handleTabClick.bind(this, index, tab.props.onClick)}>
				{tab.props.name}
			</div>
		);
	}

	render(){
		return (
			<div className="tabs">
				<div className="tab-ribbon">
					{React.Children.map(this.props.children, this.renderTabRibbonItem)}
				</div>
				{this.props.children[this.state.selectedIndex]}
			</div>
		);
	}
}

export {Tab,Tabs};