import React from 'react';

export default class PictureList extends React.Component {
	constructor(props) {
		super(props);
		this.displayDeleteButton = this.displayDeleteButton.bind(this);
		this.handleDeleteClicked = this.handleDeleteClicked.bind(this);
	}

	handleDeleteClicked(e, id, description) {
		e.preventDefault();
		this.props.deletePicture(id, description);
	}

	displayDeleteButton(id, description) {
		return (
			<a onClick={(e) => this.handleDeleteClicked(e, id, description)} className="icon delete-abstract">
				<svg viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg"><g><path d="M 30 10 L 10 30 L 40 60 L 10 90 L 30 110 L 60 80 L 90 110 L 110 90 L 80 60 L 110 30 L 90 10 L 60 40 L 30 10 z"/></g></svg>
			</a>
		);
	}

	displayPictureItem(item, i) {
		return (
			<div className="picture-item" key={i} onClick={this.props.onImageSelected.bind(this, item)}>
				{ this.displayDeleteButton(item.id, item.description) }
				<img className="thumb64" src={ this.props.thumbPath + item.filename } />
				<div className="picture-item-metadata">
					<div className="picture-item-description">{item.description}</div>
					<div className="picture-item-upload-date">{item.uploadDate}</div>
				</div>
			</div>
		);
	}

	render() {
		var list = this.props.picture || [];
		return (
			<div>
				{ list.map(this.displayPictureItem.bind(this)) }
			</div>
		);
	}
}