import React from 'react';

export default class IfExists extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		if (!this.props.value) return null;
		return (
			<React.Fragment>{this.props.children}</React.Fragment>
		);
	}
}