import React from 'react';

export default class Dialog extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		if (!this.props.visible) return null;
		return (
			<div id="modal-background" onClick={this.props.onClose}>
				{this.props.children}
			</div>
		);
	}
}