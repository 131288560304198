import React from 'react';

export default class CodeArea extends React.Component {
	constructor(props) {
		super(props);
		this.handleKeyDown = this.handleKeyDown.bind(this);
	}

	handleKeyDown(e) {
		if (e.keyCode === 9) { // tab
			e.preventDefault();
			var val = e.target.value;
			var start = e.target.selectionStart;
			e.target.value = val.substr(0, start) + "\t" + val.substr(e.target.selectionEnd);
			e.target.selectionStart = e.target.selectionEnd = start + 1;
		}
	}

	render() {
		return (
			<textarea
				name={this.props.name}
				className={this.props.className}
				onKeyDown={this.handleKeyDown}
				onChange={this.props.onChange}
				value={this.props.value}></textarea>
		);
	}
}