import React from 'react';
import Dialog from './Dialog';
import PictureList from './PictureList';
import UploadPicture from './UploadPicture';

export default class ManageImages extends React.Component {
	constructor(props) {
		super(props);
		this.ModeEnum = Object.freeze({
			BROWSE: 1,
			EDIT: 2,
			UPLOAD: 3,
			PREVIEW: 4
		});
		this.state = {
			showDialog: false,
			pictureList: null,
			mode: this.ModeEnum.BROWSE
		};
		this.openDialog = this.openDialog.bind(this);
		this.closeDialog = this.closeDialog.bind(this);
		this.doNotCloseIfClicked = this.doNotCloseIfClicked.bind(this);
		this.handleUploadComplete = this.handleUploadComplete.bind(this);
		this.showBrowse = this.showBrowse.bind(this);
		this.showEdit = this.showEdit.bind(this);
		this.showUpload = this.showUpload.bind(this);
		this.showPreview = this.showPreview.bind(this);
	}

	openDialog(e) {
		e.preventDefault();
		this.setState({ showDialog: true });
	}

	closeDialog(e) {
		e.preventDefault();
		this.setState({
			showDialog: false,
			mode: this.ModeEnum.BROWSE
		});
	}

	doNotCloseIfClicked(e) {
		e.stopPropagation();
	}

	handleUploadComplete(data) {
		this.setState({ mode: this.ModeEnum.PREVIEW });
	}

	showBrowse() {
		if (!this.state.pictureList) {
			this.props.getPictures(function(result){
				if (result) {
					this.setState({ pictureList: result });
				}
			}.bind(this));
		}
		return (
			<React.Fragment>
				<PictureList 
					thumbPath={this.props.thumbPath} 
					picture={this.state.pictureList} 
					deletePicture={this.props.deletePicture}
					onImageSelected={this.props.onImageSelected} />
				<button onClick={()=>this.setState({ mode: this.ModeEnum.UPLOAD })}>Upload Image</button>
			</React.Fragment>
		);
	}

	showEdit() {
		return (
			<React.Fragment>
				Edit Image
				<button onClick={()=>this.setState({ mode: this.ModeEnum.BROWSE })}>Cancel</button>
			</React.Fragment>
		);
	}

	showUpload() {
		return (
			<React.Fragment>
				<UploadPicture onUploadComplete={this.handleUploadComplete} />
				<button onClick={()=>this.setState({ mode: this.ModeEnum.BROWSE })}>Cancel</button>
			</React.Fragment>
		);
	}

	showPreview() {
		return (
			<div>Preview</div>
		);
	}

	render() {
		return (
			<React.Fragment>
				<button onClick={this.openDialog}>Browse Images</button>
				<Dialog visible={this.state.showDialog} onClose={this.state.closeDialog}>
					<div id="dialog-box" onClick={this.doNotCloseIfClicked}>
						<div id="dialog-box-close" onClick={this.closeDialog}>Close</div>
						{ this.state.mode === this.ModeEnum.BROWSE? this.showBrowse() : null }
						{ this.state.mode === this.ModeEnum.EDIT? this.showEdit() : null }
						{ this.state.mode === this.ModeEnum.UPLOAD? this.showUpload() : null }
						{ this.state.mode === this.ModeEnum.PREVIEW? this.showPreview() : null }
					</div>
				</Dialog>
			</React.Fragment>
		);
	}
}