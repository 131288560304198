import React from 'react';
import {Tab,Tabs} from '../components/Tabs';
import CodeArea from '../components/CodeArea';
import ManageImages from '../components/ManageImages';
import PostHelpers from '../helpers/post-helpers';
import IfExists from '../components/IfExists';

var rootUrl = (window.globals && window.globals.rootUrl) || "../";

export default class EditPost extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			Title: props.viewModel.Title.Value || "",
			TitleError: props.viewModel.Title.ServerErrors || "",
			Slug: props.viewModel.Slug.Value || "",
			SlugError: props.viewModel.Slug.ServerErrors || "",
			Active: props.viewModel.Active.Value || false,
			PreviewImage: props.viewModel.PreviewImage.Value || -1,
			PreviewImageError: props.viewModel.PreviewImage.ServerErrors || "",
			Blurb: props.viewModel.Blurb.Value || "",
			BlurbError: props.viewModel.Blurb.ServerErrors || "",
			PreviewImagePreview: "",
			Body: props.viewModel.Body.Value || "",
			BodyError: props.viewModel.Body.ServerErrors || "",
			BodyCursorPosition: 0,
			ParsedBody: ""
		};
		this.handleActiveChange = this.handleActiveChange.bind(this);
		this.handleTitleChange = this.handleTitleChange.bind(this);
		this.handleSlugChange = this.handleSlugChange.bind(this);
		this.handleBlurbChange = this.handleBlurbChange.bind(this);
		this.handleBodyChange = this.handleBodyChange.bind(this);
		this.handleImageSelected = this.handleImageSelected.bind(this);
		this.handlePreviewImageSelected = this.handlePreviewImageSelected.bind(this);
		this.handlePreviewingBody = this.handlePreviewingBody.bind(this);
		this.getSlugFromTitle = this.getSlugFromTitle.bind(this);
		this.showError = this.showError.bind(this);
	}

	handleActiveChange(e) {
		this.setState({
			Active: !!e.target.checked
		});
	}

	handleTitleChange(e) {
		e.preventDefault();
		this.setState({
			Title: e.target.value,
			TitleError: null
		});
	}

	handleSlugChange(e) {
		e.preventDefault();
		this.setState({
			Slug: e.target.value,
			SlugError: null
		});
	}

	handleBlurbChange(e) {
		e.preventDefault();
		this.setState({
			Blurb: e.target.value,
			BlurbError: null
		});
	}

	handleBodyChange(e) {
		e.preventDefault();
		this.setState({
			Body: e.target.value,
			BodyError: null,
			BodyCursorPosition: e.target.selectionEnd
		});
	}

	handleImageSelected(imageData) {
		var imgTemplate = `<img src="{{imagePath}}${imageData.filename}" alt="${imageData.description}" />`;
		var curPos = this.state.BodyCursorPosition;
		var body = this.state.Body;
		var newBody = [body.slice(0, curPos), imgTemplate, body.slice(curPos)].join('');
		this.setState({
			Body: newBody,
			BodyCursorPosition: curPos + imgTemplate.length
		});
	}

	handlePreviewImageSelected(imageData) {
		var imgTemplate = `<img src="${rootUrl}media/${imageData.filename}" alt="${imageData.description}" />`;
		this.setState({
			PreviewImage: imageData.id,
			PreviewImagePreview: imgTemplate
		});
	}

	handlePreviewingBody() {
		PostHelpers.sanitizePostBody(this.state.Body, function(sanitizedPostBody){
			this.setState({
				ParsedBody: sanitizedPostBody
			});
		}.bind(this));
	}

	getSlugFromTitle() {
		PostHelpers.slugify(this.state.Title, function(slugifiedTitle){
			this.setState({
				Slug: slugifiedTitle
			});
		}.bind(this));
	}

	showError(errorMessage) {
		return (
			<div className="error">{errorMessage}</div>
		);
	}

	render() {
		return (
			<form method="post" className="edit-post-form">
				<input type="hidden" name="__RequestVerificationToken" value={window.globals.csrfToken} />
				<input type="hidden" name="Id" value={this.props.viewModel.Id? this.props.viewModel.Id.Value : ""} />
				<header className="post-form-header">
					<h2>Create Post</h2>
					<span className="post-active">
						Active:
						<input type="checkbox" name="Active" value="true" checked={this.state.Active} onChange={this.handleActiveChange} />
					</span>
				</header>
				<div className="field">
					<label htmlFor="title">Title</label>
					<input type="text" name="Title" value={this.state.Title} onChange={this.handleTitleChange} />
					<IfExists value={this.state.TitleError}>
						<div className="error">{this.state.TitleError}</div>
					</IfExists>
				</div>
				<div className="field">
					<label htmlFor="slug">Slug</label><a onClick={this.getSlugFromTitle} className="post-slug-copy-title">Create slug from title</a>
					<input type="text" name="Slug" value={this.state.Slug} onChange={this.handleSlugChange} />
					<IfExists value={this.state.SlugError}>
						<div className="error">{this.state.SlugError}</div>
					</IfExists>
				</div>
				<Tabs>
					<Tab name="Edit Teaser">
						<div className="rows">
							<div className="one-part left">
								<div className="field">
									<label htmlFor="blurb">Blurb</label>
									<textarea name="Blurb" className="blurb" value={this.state.Blurb} onChange={this.handleBlurbChange}></textarea>
									<IfExists value={this.state.BlurbError}>
										<div className="error">{this.state.BlurbError}</div>
									</IfExists>
								</div>
							</div>
							<div className="one-part right">
								<input type="hidden" name="PreviewImage" value={this.state.PreviewImage} />
								<ManageImages 
									thumbPath={ rootUrl + 'media/'} 
									getPictures={PostHelpers.getPictures} 
									deletePicture={PostHelpers.deletePicture} 
									onImageSelected={this.handlePreviewImageSelected} />
								<div style={{maxWidth: "40%"}} dangerouslySetInnerHTML={{__html: this.state.PreviewImagePreview}}></div>
							</div>
						</div>
					</Tab>
					<Tab name="Preview Teaser">
						{ this.state.Blurb }
						{ this.state.PreviewImage }
					</Tab>
				</Tabs>
				<Tabs>
					<Tab name="Edit Body">
						<ManageImages 
							thumbPath={ rootUrl + 'media/'} 
							getPictures={PostHelpers.getPictures} 
							deletePicture={PostHelpers.deletePicture} 
							onImageSelected={this.handleImageSelected} />
						<CodeArea
							name="Body"
							className="post-body"
							onChange={this.handleBodyChange}
							value={this.state.Body}></CodeArea>
						<IfExists value={this.state.BodyError}>
							<div className="error">{this.state.BodyError}</div>
						</IfExists>
					</Tab>
					<Tab name="Preview Body" onClick={this.handlePreviewingBody}>
						<div className="blog-post-preview" dangerouslySetInnerHTML={{__html: this.state.ParsedBody}}></div>
					</Tab>
				</Tabs>
				<input type="submit" value="Submit" />
			</form>
		);
	}
}